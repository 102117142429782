import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import "./TinyMCE.css";

export default function TinyMCE({
  value,
  onChange = () => {},
  RSSToolbar = false,
  ListingToolbar = false,
  height,
  toolbar,
  width,
  editor = false,
  toolbar_location,
  inline_toolbar = "",
  changeContent = () => {},
}) {
  const editorRef = useRef(null);
  console.log("valuessdfaedfwedwq", value);
  return (
    <>
      <Editor
        onInit={(event, editor) => (editorRef.current = editor)}
        tinymceScriptSrc={"/tinymce/tinymce.min.js"}
        value={value}
        onEditorChange={(html, editor) => {
          const jsonContent = editor.getContent({ format: "text" });
          onChange(html);
          changeContent(jsonContent, html);
        }}
        // {...(RSSToolbar
        //   ? {
        //       initialValue: "",
        //     }
        //   : {
        //       initialValue: value,
        //     },
        // { textareaName: "content", outputFormat: "html" })}
        init={{
          valid_children: "+body[style]",
          extended_valid_elements: "style[type]",
          menubar: false,
          statusbar: false,
          toolbar_mode: "wrap",
          noneditable_class: "nonedit",
          editable_class: "editable",
          ...(editor
            ? {
                placeholder: "Start writing here",
                inline: true,
                fixed_toolbar_container: ".tinymce-toolbar",
                toolbar_sticky: true,
                toolbar_sticky_offset: 108,
                height: { height },
                toolbar_mode: "floating",
                toolbar: `mergetags fontfamily fontsize  bold italic underline | ${inline_toolbar} | numlist bullist | forecolor backcolor | outdent indent | alignleft aligncenter alignright alignjustify | removeformat strikethrough link `,
              }
            : {
                placeholder: "Type something here...",
                height: height || 400,
                toolbar:
                  toolbar ||
                  " mergetags bold italic underline blockquote rewrite ",
                width: width || "100%",
                toolbar_location: toolbar_location || "auto",
              }),
          plugins:
            "preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons",
          image_advtab: true,
          link_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_class_list: [
            { title: "None", value: "" },
            { title: "Some class", value: "class-name" },
          ],
          importcss_append: true,
          file_picker_callback: (callback, value, meta) => {
            /* Provide file and text for the link dialog */
            if (meta.filetype === "file") {
              callback("https://www.google.com/logos/google.jpg", {
                text: "My text",
              });
            }

            /* Provide image and alt text for the image dialog */
            if (meta.filetype === "image") {
              callback("https://www.google.com/logos/google.jpg", {
                alt: "My alt text",
              });
            }

            /* Provide alternative source and posted for the media dialog */
            if (meta.filetype === "media") {
              callback("movie.mp4", {
                source2: "alt.ogg",
                poster: "https://www.google.com/logos/google.jpg",
              });
            }
          },
          templates: [
            {
              title: "New Table",
              description: "creates a new table",
              content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
            },
            {
              title: "Starting my story",
              description: "A cure for writers block",
              content: "Once upon a time...",
            },
            {
              title: "New list with dates",
              description: "New List with dates",
              content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
            },
          ],
          template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
          template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
          image_caption: true,
          paste_data_images: true,
          contextmenu: "link | paste |  image table",
          skin: false ? "oxide-dark" : "oxide",
          content_css: false ? "dark" : "default",
          // content_style: `
          //   body { font-family:Helvetica,Arial,sans-serif; font-size:16px }
          //    table { border-collapse: collapse; width: 100%; }
          //    table, th, td { border: 1px solid #ccc; padding: 8px; }
          //   .tag {
          //     display:inline;
          //     background-color:purple;
          //     color:#FFF;
          //     padding:4px 10px;
          //     border-radius: 0.5em;
          // }
          // `,
          link_context_toolbar: true,

          setup: (editor) => {
            editor.on("mousedown", function (e) {
              const target = e.target;
              const innerDivSelector = ".mce-token";
              // // Check if the clicked element is the inner <div> or a child of it
              if (
                target.matches(innerDivSelector) ||
                target.closest(innerDivSelector)
              ) {
                // console.log('yes')
                // e.stopPropagation()
              }
            });

            editor.ui.registry.addIcon(
              "bot",
              `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-robot" viewBox="0 0 16 16">
              <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5ZM3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.58 26.58 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.933.933 0 0 1-.765.935c-.845.147-2.34.346-4.235.346-1.895 0-3.39-.2-4.235-.346A.933.933 0 0 1 3 9.219V8.062Zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a24.767 24.767 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25.286 25.286 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135Z"/>
              <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2V1.866ZM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5Z"/>
            </svg>
      
      `
            );
            editor.ui.registry.addToggleButton("customStrikethrough", {
              text: "Strikethrough",
              onAction: (api) => {
                editor.execCommand("mceToggleFormat", false, "strikethrough");
                api.setActive(!api.isActive());
              },
            });
            editor.ui.registry.addButton("imgUpload", {
              text: "",
              icon: "image",
              onAction: function (_) {
                const imageInput = document.getElementById("imgInput");
                imageInput.click();
              },
            });
            editor.ui.registry.addButton("fileUpload", {
              text: "",
              icon: "upload",
              onAction: function (_) {
                const fileInput = document.getElementById("fileInput");
                fileInput.click();
              },
            });
            editor.ui.registry.addButton("rewrite", {
              text: "",
              icon: "openAI",
              onAction: function (_) {
                const text = editor.selection.getContent();
                console.log("text", text);
                if (!text) {
                  toast.warn("Select some text first.");
                  return;
                }
              },
            });

            editor.ui.registry.addToggleButton("customToggleStrikethrough", {
              icon: "strike-through",
              onAction: (_) =>
                editor.execCommand("mceToggleFormat", false, "strikethrough"),
              onSetup: (api) => {
                api.setActive(editor.formatter.match("strikethrough"));
                const changed = editor.formatter.formatChanged(
                  "strikethrough",
                  (state) => api.setActive(state)
                );
                return () => changed.unbind();
              },
            });
            editor.ui.registry.addMenuButton("mergetags", {
              text: "",
              icon: "addtag",
              fetch: (callback) => {
                const prefix =
                  '<span class="tag link" contenteditable="false" data-crossflow="malort420">';
                const suffix = "</span>";

                const items = [
                  ...(RSSToolbar
                    ? [
                        {
                          type: "nestedmenuitem",
                          text: "RSS Tags",
                          getSubmenuItems: function () {
                            return [
                              {
                                type: "menuitem",
                                text: "Title",
                                onAction: () => {
                                  editor.insertContent(`
                          <img
                            class="mce-token"
                            style="
                              width: 73px !important;
                              height: 28px !important;
                              background: #ffffff;
                              border: 1px solid #99acc2;
                              border-radius: 2px;
                              vertical-align: middle;
                              box-sizing: border-box;
                            "
                            src="/tinymce-images/title.png"
                            data-mce-property-name="title"
                            data-popover-id="uiopenpopup-92"
                            data-mce-selected="1"
                          />
                        `);
                                },
                              },
                              {
                                type: "menuitem",
                                text: "Author Line",
                                onAction: () => {
                                  editor.insertContent(`
                          <img
                            class="mce-token"
                            style="
                              width: 128px !important;
                              height: 28px !important;
                              background: #ffffff;
                              border: 1px solid #99acc2;
                              border-radius: 2px;
                              vertical-align: middle;
                              box-sizing: border-box;
                            "
                            src="/tinymce-images/author.png"
                            data-mce-property-name="author_line"
                            data-popover-id="uiopenpopup-106"
                            data-mce-selected="1"
                          />
                        `);
                                },
                              },
                              {
                                type: "menuitem",
                                text: "Publish date/time",
                                onAction: () => {
                                  editor.insertContent(`
                          <img
                            class="mce-token"
                            style="
                              width: 177px !important;
                              height: 28px !important;
                              background: #ffffff;
                              border: 1px solid #99acc2;
                              border-radius: 2px;
                              vertical-align: middle;
                              box-sizing: border-box;
                            "
                            src="/tinymce-images/publish.png"
                            data-mce-property-name="publish_date_time"
                          />
                        `);
                                },
                              },
                              {
                                type: "menuitem",
                                text: "Publish date",
                                onAction: () => {
                                  editor.insertContent(`
                          <img
                            class="mce-token"
                            style="
                              width: 140px !important;
                              height: 28px !important;
                              background: #ffffff;
                              border: 1px solid #99acc2;
                              border-radius: 2px;
                              vertical-align: middle;
                              box-sizing: border-box;
                            "
                            src="/tinymce-images/publish_date.png"
                            data-mce-property-name="publish_date"
                          />
                        `);
                                },
                              },
                              {
                                type: "menuitem",
                                text: "Featured Image",
                                onAction: () => {
                                  editor.insertContent(`
                          <img
                            class="mce-token"
                            style="
                              width: 162px !important;
                              height: 28px !important;
                              background: #ffffff;
                              border: 1px solid #99acc2;
                              border-radius: 2px;
                              vertical-align: middle;
                              box-sizing: border-box;
                            "
                            src="/tinymce-images/image.png"
                            data-mce-property-name="featured_image"
                          />
                        `);
                                },
                              },
                              {
                                type: "menuitem",
                                text: "Summary",
                                onAction: () => {
                                  editor.insertContent(`
                          <img
                            class="mce-token"
                            style="
                              width: 114px !important;
                              height: 28px !important;
                              background: #ffffff;
                              border: 1px solid #99acc2;
                              border-radius: 2px;
                              vertical-align: middle;
                              box-sizing: border-box;
                            "
                            src="/tinymce-images/summary.png"
                            data-mce-property-name="summary"                      
                          />
                        `);
                                },
                              },
                              {
                                type: "menuitem",
                                text: "Read More",
                                onAction: () => {
                                  editor.insertContent(`<span 
                          data-mce-property-name="read_more"
                          style="color:blue;text-decoration:underline;cursor:pointer;"
                          >
                          Read more &gt;&gt;
                          </span>`);
                                },
                              },
                            ];
                          },
                        },
                      ]
                    : []),
                  ...(ListingToolbar
                    ? [
                        {
                          type: "nestedmenuitem",
                          text: "Listing Tags",
                          getSubmenuItems: function () {
                            return [
                              {
                                type: "menuitem",
                                text: "Title",
                                onAction: () => {
                                  editor.insertContent(`
                            <img
                              class="mce-token"
                              style="
                                width: 73px !important;
                                height: 28px !important;
                                background: #ffffff;
                                border: 1px solid #99acc2;
                                border-radius: 2px;
                                vertical-align: middle;
                                box-sizing: border-box;
                              "
                              src="/tinymce-images/title.png"
                              data-mce-property-name="title"
                              data-popover-id="uiopenpopup-92"
                              data-mce-selected="1"
                            />
                          `);
                                },
                              },
                              {
                                type: "menuitem",
                                text: "Featured Image",
                                onAction: () => {
                                  editor.insertContent(`
                            <img
                              class="mce-token"
                              style="
                                width: 128px !important;
                                height: 28px !important;
                                background: #ffffff;
                                border: 1px solid #99acc2;
                                border-radius: 2px;
                                vertical-align: middle;
                                box-sizing: border-box;
                              "
                              src="/tinymce-images/image.png"
                              data-mce-property-name="featured_image"
                              data-popover-id="uiopenpopup-106"
                              data-mce-selected="1"
                            />
                          `);
                                },
                              },
                              {
                                type: "menuitem",
                                text: "Auction End Date",
                                onAction: () => {
                                  editor.insertContent(`
                            <img
                              class="mce-token"
                              style="
                                width: 177px !important;
                                height: 28px !important;
                                background: #ffffff;
                                border: 1px solid #99acc2;
                                border-radius: 2px;
                                vertical-align: middle;
                                box-sizing: border-box;
                              "
                              src="/tinymce-images/auction-end-date.png"
                              data-mce-property-name="auction_end_date"
                            />
                          `);
                                },
                              },
                              {
                                type: "menuitem",
                                text: "Open House Dates",
                                onAction: () => {
                                  editor.insertContent(`
                            <img
                              class="mce-token"
                              style="
                                width: 140px !important;
                                height: 28px !important;
                                background: #ffffff;
                                border: 1px solid #99acc2;
                                border-radius: 2px;
                                vertical-align: middle;
                                box-sizing: border-box;
                              "
                              src="/tinymce-images/open-house-date.png"
                              data-mce-property-name="open_house_date"
                            />
                          `);
                                },
                              },
                              {
                                type: "menuitem",
                                text: "Address",
                                onAction: () => {
                                  editor.insertContent(`
                            <img
                              class="mce-token"
                              style="
                                width: 162px !important;
                                height: 28px !important;
                                background: #ffffff;
                                border: 1px solid #99acc2;
                                border-radius: 2px;
                                vertical-align: middle;
                                box-sizing: border-box;
                              "
                              src="/tinymce-images/address.png"
                              data-mce-property-name="address"
                            />
                          `);
                                },
                              },
                              {
                                type: "menuitem",
                                text: "Rooms Size",
                                onAction: () => {
                                  editor.insertContent(`
                            <img
                              class="mce-token"
                              style="
                                width: 114px !important;
                                height: 28px !important;
                                background: #ffffff;
                                border: 1px solid #99acc2;
                                border-radius: 2px;
                                vertical-align: middle;
                                box-sizing: border-box;
                              "
                              src="/tinymce-images/room-size.png"
                              data-mce-property-name="rooms_size"                   
                            />
                          `);
                                },
                              },
                              {
                                type: "menuitem",
                                text: "Description",
                                onAction: () => {
                                  editor.insertContent(`
                            <img
                              class="mce-token"
                              style="
                                width: 114px !important;
                                height: 28px !important;
                                background: #ffffff;
                                border: 1px solid #99acc2;
                                border-radius: 2px;
                                vertical-align: middle;
                                box-sizing: border-box;
                              "
                              src="/tinymce-images/description.png"
                              data-mce-property-name="description"                      
                            />
                          `);
                                },
                              },

                              {
                                type: "menuitem",
                                text: "AUCTION CRAIGSLIST REDIRECT",
                                onAction: () => {
                                  editor.insertContent(`
                            <img
                              class="mce-token"
                              style="
                                width: 114px !important;
                                height: 28px !important;
                                background: #ffffff;
                                border: 1px solid #99acc2;
                                border-radius: 2px;
                                vertical-align: middle;
                                box-sizing: border-box;
                              "
                              src="/tinymce-images/AUCTION-CRAIGSLIST-REDIRECT.png"
                              data-mce-property-name="AUCTION_CRAIGSLIST_REDIRECT"                      
                            />
                          `);
                                },
                              },

                              {
                                type: "menuitem",
                                text: "AUCTION SIGN QR REDIRECT",
                                onAction: () => {
                                  editor.insertContent(`
                            <img
                              class="mce-token"
                              style="
                                width: 114px !important;
                                height: 28px !important;
                                background: #ffffff;
                                border: 1px solid #99acc2;
                                border-radius: 2px;
                                vertical-align: middle;
                                box-sizing: border-box;
                              "
                              src="/tinymce-images/AUCTION-SIGN-QR-REDIRECT.png"
                              data-mce-property-name="AUCTION_SIGN_QR_REDIRECT"                      
                            />
                          `);
                                },
                              },

                              {
                                type: "menuitem",
                                text: "AUCTION MAILER REDIRECT",
                                onAction: () => {
                                  editor.insertContent(`
                            <img
                              class="mce-token"
                              style="
                                width: 114px !important;
                                height: 28px !important;
                                background: #ffffff;
                                border: 1px solid #99acc2;
                                border-radius: 2px;
                                vertical-align: middle;
                                box-sizing: border-box;
                              "
                              src="/tinymce-images/AUCTION-MAILER-REDIRECT.png"
                              data-mce-property-name="AUCTION_MAILER_REDIRECT"                      
                            />
                          `);
                                },
                              },

                              {
                                type: "menuitem",
                                text: "AUCTION FACEBOOK POST REDIRECT",
                                onAction: () => {
                                  editor.insertContent(`
                            <img
                              class="mce-token"
                              style="
                                width: 114px !important;
                                height: 28px !important;
                                background: #ffffff;
                                border: 1px solid #99acc2;
                                border-radius: 2px;
                                vertical-align: middle;
                                box-sizing: border-box;
                              "
                              src="/tinymce-images/AUCTION-FACEBOOK-POST-REDIRECT.png"
                              data-mce-property-name="AUCTION_FACEBOOK_POST_REDIRECT"                      
                            />
                          `);
                                },
                              },

                              {
                                type: "menuitem",
                                text: "AUCTION FACEBOOK AD REDIRECT",
                                onAction: () => {
                                  editor.insertContent(`
                            <img
                              class="mce-token"
                              style="
                                width: 114px !important;
                                height: 28px !important;
                                background: #ffffff;
                                border: 1px solid #99acc2;
                                border-radius: 2px;
                                vertical-align: middle;
                                box-sizing: border-box;
                              "
                              src="/tinymce-images/AUCTION-FACEBOOK-AD-REDIRECT.png"
                              data-mce-property-name="AUCTION_FACEBOOK_AD_REDIRECT"                      
                            />
                          `);
                                },
                              },

                              {
                                type: "menuitem",
                                text: " AUCTION GOOGLE AD REDIRECT",
                                onAction: () => {
                                  editor.insertContent(`
                            <img
                              class="mce-token"
                              style="
                                width: 114px !important;
                                height: 28px !important;
                                background: #ffffff;
                                border: 1px solid #99acc2;
                                border-radius: 2px;
                                vertical-align: middle;
                                box-sizing: border-box;
                              "
                              src="/tinymce-images/AUCTION-GOOGLE-AD-REDIRECT.png"
                              data-mce-property-name="AUCTION_GOOGLE_AD_REDIRECT"                      
                            />
                          `);
                                },
                              },
                            ];
                          },
                        },
                      ]
                    : []),
                  {
                    type: "nestedmenuitem",
                    text: "Agent Tags",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Agent First Name",

                          onAction: () => {
                            editor.insertContent(`${
                              RSSToolbar
                                ? `<span
                            class="mce-token"
                            contenteditable='false'
                            style="
                                display:block;
                                white-space: nowrap;
                                width: 150px !important;
                                height: 30px !important;
                                font-size: 16px !important;
                                font-weight:normal !important;
                                padding:5px 10px !important;
                                background: #ffffff;
                                border: 1px solid #99acc2;
                                border-radius: 2px;
                                vertical-align: middle;
                                box-sizing: border-box;
  
                              "
                            data-mce-property-name="agent_first_name"
                            data-popover-id="uiopenpopup-106"
                            data-mce-selected="1"
                            >
                            
                            Agent First Name</span><br/>`
                                : `<span style=' height: 30px !important;
                            padding:2px 1px;' contenteditable='false'>[Agent_First_Name]
                            </span>`
                            }
                            
                          
                          
                        `);
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Agent Last Name",
                          onAction: () => {
                            editor.insertContent(`${
                              RSSToolbar
                                ? `
                                
                                <span
                                class="mce-token"
                                contenteditable='false'
                                style="
                                display:block;
                                white-space: nowrap;
                                width: 150px !important;
                                    height: 30px !important;
                                    font-size: 16px !important;
                                    font-weight:normal !important;
                                    padding:5px 10px !important;
                                    background: #ffffff;
                                    border: 1px solid #99acc2;
                                    border-radius: 2px;
                                    vertical-align: middle;
                                    box-sizing: border-box;
                                    display:block;
      
                                  "
                                data-mce-property-name="agent_last_name"
                                data-popover-id="uiopenpopup-106"
                                data-mce-selected="1"
                                >
                                
                                Agent Last Name</span>
                                <br/>
                                
                              `
                                : `<span style=' height: 30px !important;
                            padding:2px 1px;' contenteditable='false'>[Agent_Last_Name]
                            </span>`
                            }
                            
                          
                          
                        `);
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Agent Cell Phone",
                          onAction: () => {
                            editor.insertContent(`${
                              RSSToolbar
                                ? `<span
                                  class="mce-token"
                                  contenteditable='false'
                                  style="
                                  display:block;
                                  white-space: nowrap;
                                  width: 150px !important;
                                      height: 30px !important;
                                      font-size: 16px !important;
                                      font-weight:normal !important;
                                      padding:5px 10px !important;
                                      background: #ffffff;
                                      border: 1px solid #99acc2;
                                      border-radius: 2px;
                                      vertical-align: middle;
                                      box-sizing: border-box;
                                      display:block;
        
                                    "
                                  data-mce-property-name="agent_cell_phone"
                                  data-popover-id="uiopenpopup-106"
                                  data-mce-selected="1"
                                  >
                                  
                                  Agent Cell Phone</span>`
                                : `<span style=' height: 30px !important;
                              padding:2px 1px;' contenteditable='false'>[Agent_Cell_Phone]
                              </span>`
                            }
                              
                            
                            
                          `);
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Agent Office Phone",
                          onAction: () => {
                            editor.insertContent(
                              `${
                                RSSToolbar
                                  ? `<span
                                contenteditable='false'
                                class="mce-token"
                                style="
                                display:block;
                                white-space: nowrap;
                                width: 160px !important;
                                    height: 30px !important;
                                    font-size: 16px !important;
                                    font-weight:normal !important;
                                    padding:5px 10px !important;
                                    background: #ffffff;
                                    border: 1px solid #99acc2;
                                    border-radius: 2px;
                                    vertical-align: middle;
                                    box-sizing: border-box;
                                    display:block;
      
                                  "
                                data-mce-property-name="agent_office_phone"
                                data-popover-id="uiopenpopup-106"
                                data-mce-selected="1"
                                
                                >
                                
                                Agent Office Phone</span>`
                                  : `<span style=' height: 30px !important;
                              padding:2px 1px;' contenteditable='false'>[Agent_Office_Phone]
                              </span>`
                              }`
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Agent License Number",
                          onAction: () => {
                            editor.insertContent(
                              `${
                                RSSToolbar
                                  ? `
                                  <span
                                  contenteditable='false'
                                  class="mce-token"
                                  style="
                                  display:block;
                                  white-space: nowrap;
                                  width: 180px !important;
                                      height: 30px !important;
                                      font-size: 16px !important;
                                      font-weight:normal !important;
                                      padding:5px 10px !important;
                                      background: #ffffff;
                                      border: 1px solid #99acc2;
                                      border-radius: 2px;
                                      vertical-align: middle;
                                      box-sizing: border-box;
                                      display:block;
        
                                    "
                                  data-mce-property-name="agent_license_number"
                                  data-popover-id="uiopenpopup-106"
                                  data-mce-selected="1"
                                  
                                  >
                                  
                                  Agent License Number
                                  </span>
                                  <br/>`
                                  : `<span style=' height: 30px !important;
                              padding:2px 1px;' contenteditable='false'>[Agent_License_Number]
                              </span>`
                              }`
                            );
                          },
                        },
                      ];
                    },
                  },
                  {
                    type: "nestedmenuitem",
                    text: "Contact Tags",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Contact First Name",
                          onAction: () => {
                            editor.insertContent(`
                            ${
                              RSSToolbar
                                ? `
                                <span
                                contenteditable='false'
                                class="mce-token"
                                style="
                                display:block;
                                white-space: nowrap;
                                width: 180px !important;
                                    height: 30px !important;
                                    font-size: 16px !important;
                                    font-weight:normal !important;
                                    padding:5px 10px !important;
                                    background: #ffffff;
                                    border: 1px solid #99acc2;
                                    border-radius: 2px;
                                    vertical-align: middle;
                                    box-sizing: border-box;
                                    display:block;
      
                                  "
                                data-mce-property-name="contact_first_name"
                                data-popover-id="uiopenpopup-106"
                                data-mce-selected="1"
                                
                                >
                                
                                Contact First Name
                                </span>
                                <br/>`
                                : `<span style=' height: 30px !important;
                            padding:2px 1px;' contenteditable='false'>[Contact_First_Name]
                            </span>`
                            }
                        `);
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Contact Last Name",
                          onAction: () => {
                            editor.insertContent(`
                            ${
                              RSSToolbar
                                ? `
                                <span
                                contenteditable='false'
                                class="mce-token"
                                style="
                                display:block;
                                white-space: nowrap;
                                width: 180px !important;
                                    height: 30px !important;
                                    font-size: 16px !important;
                                    font-weight:normal !important;
                                    padding:5px 10px !important;
                                    background: #ffffff;
                                    border: 1px solid #99acc2;
                                    border-radius: 2px;
                                    vertical-align: middle;
                                    box-sizing: border-box;
                                    display:block;
      
                                  "
                                data-mce-property-name="contact_last_name"
                                data-popover-id="uiopenpopup-106"
                                data-mce-selected="1"
                                
                                >
                                
                                Contact Last Name
                                </span>
                                <br/>`
                                : `<span style=' height: 30px !important;
                            padding:2px 1px;' contenteditable='false'>[Contact_Last_Name]
                            </span>`
                            }
                          
                        `);
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Contact Full Name",
                          onAction: () => {
                            editor.insertContent(`
                            ${
                              RSSToolbar
                                ? `
                                <span
                                contenteditable='false'
                                class="mce-token"
                                style="
                                display:block;
                                white-space: nowrap;
                                width: 180px !important;
                                    height: 30px !important;
                                    font-size: 16px !important;
                                    font-weight:normal !important;
                                    padding:5px 10px !important;
                                    background: #ffffff;
                                    border: 1px solid #99acc2;
                                    border-radius: 2px;
                                    vertical-align: middle;
                                    box-sizing: border-box;
                                    display:block;
      
                                  "
                                data-mce-property-name="contact_full_name"
                                data-popover-id="uiopenpopup-106"
                                data-mce-selected="1"
                                
                                >
                                
                                Contact Full Name
                                </span>
                                <br/>`
                                : `<span style=' height: 30px !important;
                            padding:2px 1px;' contenteditable='false'>[Contact_Full_Name]
                            </span>`
                            }
                          
                        `);
                          },
                        },
                      ];
                    },
                  },
                ];

                callback(items);
              },
            });
          },
        }}
      />
    </>
  );
}
